import { productType as ProductType } from "~/dto/product/ProductDTO";
import { StudentMasterDTO } from "../StudentMasterDTO";

export class CartProductDTO {
  [index: string]: number | string | boolean | undefined | StudentMasterDTO;

  public id = 0;
  public cartId = 0;
  public studentGenericId = 0;
  public productType = "";
  public productId = 0;
  public deeIdFirst = 0;
  public priceRelationId = 0;
  public priceId = 0;
  public productDynamicNumber = 0;
  public isStudentGenericCompleted = false;

  public constructor(init?: Partial<CartProductDTO>) {
    if (!init) return;
    for (const [key, value] of Object.entries(init)) {
      if (Object.getOwnPropertyNames(this).includes(key)) {
        this[key] = value;
      }
    }
  }
}

export class CartProductExtended extends CartProductDTO {
  public label = "";
  public datetimeFirst = "";
  public priceLabel = "";
  public priceAmount = 0;
  public priceOnline = 0;
  public formatFrequencyType = "";
  public studentGeneric = new StudentMasterDTO();

  constructor(init?: Partial<CartProductExtended>) {
    super(init);
    if (init === undefined) return;
    Object.assign(this, init);
  }
}

export class CartProductFrontEnd extends CartProductExtended {
  constructor(init?: Partial<CartProductFrontEnd>) {
    super(init);
    if (init === undefined) return;
    Object.assign(this, init);
  }

  public storeUuid = "";

  get subLabel() {
    if (this.isCartProductSliding) {
      return this.productDynamicNumber === 1
        ? "Une activité ponctuelle"
        : this.productDynamicNumber + " activités ponctuelles consécutives";
    } else if (this.productType === ProductType.format) {
      return "";
    } else if (this.productType === ProductType.epass) {
      return "";
    } else return "";
  }

  get isCartProductSliding() {
    if (this.productDynamicNumber === undefined) {
      return false;
    }

    return this.productDynamicNumber > 0;
  }
}