import { StudentGenericDTO } from './StudentGenericDTO'
export class StudentMasterDTO extends StudentGenericDTO {

  public userWpId = 0;

  constructor(init?: Partial<StudentMasterDTO>) {
    super()
    Object.assign(this, init)
  }

}
