import { useAxios } from "@vueuse/integrations/useAxios";
import { CartProductDTO } from '@/dto/cart/CartProductDTO'
import { ClassErrorHandler } from '@/class/ClassErrorHandler'

export class CartProductDelete {

  private cartProduct = new CartProductDTO()

  constructor(cartProduct: CartProductDTO) {
    this.cartProduct = cartProduct
  }

  public async deleteCartProduct() {

    try {
      await this.deleteCartProductApi()

      return true

    } catch (error) {
      const errorHander = new ClassErrorHandler(error)
      errorHander.displayErrorIfDev()

      return false
    }
  }

  private async deleteCartProductApi() {

    const urlAxios = '/cart-product/' + this.cartProduct.id

    const { execute, data } = await useAxios(
      urlAxios,
      { method: "DELETE" }
    );

    if (!data.value) {
      throw new Error('Error with cart product id delete')
    }
  }
}
