import { CartProductFrontEnd } from "@/dto/cart/CartProductDTO";
import { CartCleanControllerDelete } from "./CartCleanProduct";
import { CartProductDelete } from "../model/CartProductDelete";

export class CartProductControllerDelete {
  private cartProduct: CartProductFrontEnd;
  private showToast = false;
  private isCartToClean = true;
  private cartStore = useCartStore();
  private toastStore = useToastStore();

  constructor(product: CartProductFrontEnd) {
    this.cartProduct = product;
  }

  /**
   * Deletes a product from the cart and performs cleanup if necessary.
   */
  public async deleteProduct(): Promise<void> {
    try {

      // SERVER
      const cartProductDeleter = new CartProductDelete(this.cartProduct);
      await cartProductDeleter.deleteCartProduct();

      // STORE
      this.cartStore.removeProductFromCart(this.cartProduct.id);

      if (this.showToast) {
        this.toastStore.setToastMessage("Produit supprimé du panier", "success");
      }

      if (this.isCartToClean) {
        const cartCleaner = new CartCleanControllerDelete();
        await cartCleaner.clean();
      }
    } catch (error) {
      this.toastStore.setToastMessage(
        "Erreur lors de la suppression du produit. Veuillez réessayer.",
        "error"
      );
      console.error("Error deleting product:", error);
    }
  }

  /**
   * Deletes the cart product from the server.
   */
  private async deleteCartProductFromServer(): Promise<void> {
    const cartProductDeleter = new CartProductDelete(this.cartProduct);
    await cartProductDeleter.deleteCartProduct();
  }

  /**
   * Enables or disables toast notifications for this operation.
   */
  public setShowToast(value: boolean): void {
    this.showToast = value;
  }

  /**
   * Toggles whether the cart should be cleaned after product deletion.
   */
  public setIsCartToClean(value: boolean): void {
    this.isCartToClean = value;
  }
}
