import { CartProductFrontEnd } from "@/dto/cart/CartProductDTO";
import { CartDiscountExtend } from "@/dto/cart/CartDiscountDTO";
import { CartProductControllerDelete } from "./CartProductDelete";
import { ProductType } from "@/class/KeyProducts";
import { CartControllerUpdate } from "@/class/cart/controller/CartUpdate";
import { CartDiscountControllerDelete } from "@/class/cart/controller/CartDiscountDelete";
import { CartDTO } from "@/dto/cart/CartDTO";

export class CartCleanControllerDelete {
  /**
   * Cleans the cart by removing additional products and discounts if necessary.
   */
  public async clean(): Promise<void> {
    await this.cleanAdditionalProducts();
    await this.cleanProductsAndDiscountsIfNoMainProducts();
  }

  /**
   * Removes additional products from the cart if they are not associated with
   * required products for a specific student.
   */
  private async cleanAdditionalProducts(): Promise<void> {
    const additionalProducts = this.getAdditionalProducts();

    additionalProducts.forEach((product: CartProductFrontEnd) => {
      if (this.hasRequiredMainProductsForStudent(product.studentGenericId)) {
        return;
      }

      const productDeleter = new CartProductControllerDelete(product);
      productDeleter.setIsCartToClean(false);
      productDeleter.deleteProduct();
    });
  }

  /**
   * Cleans up products and discounts if there are no main products in the cart.
   */
  private async cleanProductsAndDiscountsIfNoMainProducts(): Promise<void> {
    const mainProducts = this.cartProducts.filter(product =>
      [ProductType.epass, ProductType.format].includes(product.productType)
    );

    if (mainProducts.length > 0) {
      return;
    }

    await this.cleanCartDiscounts();

    if (this.cart.buyerOrganizerIdWhenOrga > 0) {
      // If the cart has an organizer ID, skip resetting the cart.
      return;
    }

    const cartUpdater = new CartControllerUpdate();
    cartUpdater.initCart();
    cartUpdater.update();
  }

  /**
   * Removes all discounts from the cart if there are no products.
   */
  private async cleanCartDiscounts(): Promise<void> {
    if (this.cartDiscounts.length < 1) {
      return;
    }

    this.cartDiscounts.forEach((discount: CartDiscountExtend) => {
      const discountDeleter = new CartDiscountControllerDelete(discount);
      // Uncomment the following line if toasts are needed for deletion.
      // discountDeleter.setShowToast(true);
      discountDeleter.deleteDiscount();
    });
  }

  /**
   * Filters and returns additional products in the cart (non-main products).
   */
  private getAdditionalProducts(): CartProductFrontEnd[] {
    return this.cartProducts.filter(
      product =>
        ![ProductType.epass, ProductType.format].includes(product.productType)
    );
  }

  /**
   * Checks if a student has required main products (e.g., epass or format).
   * @param studentGenericId - The ID of the student to check.
   * @returns `true` if the student has required main products; otherwise `false`.
   */
  private hasRequiredMainProductsForStudent(studentGenericId: number): boolean {
    return this.cartProducts.some(
      product =>
        product.studentGenericId === studentGenericId &&
        [ProductType.epass, ProductType.format].includes(product.productType)
    );
  }

  /**
   * Gets the current cart from the store.
   */
  private get cart(): CartDTO {
    return useCartStore().cart;
  }

  /**
   * Gets the list of products in the cart from the store.
   */
  private get cartProducts(): CartProductFrontEnd[] {
    return useCartStore().products;
  }

  /**
   * Gets the list of discounts in the cart from the store.
   */
  private get cartDiscounts(): CartDiscountExtend[] {
    return useCartStore().discounts;
  }
}
